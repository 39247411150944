@charset "UTF-8";
@import "_setting";
@import "_mixin";
html,body{
}
body{
	.guideBnr-wp {
	    display: flex;
	}
	.guideBnr-wp.engmode{
		padding-top: 70px;
	}
	.guideBnr a {
	    display: block;
	    border: #000 solid 2px;
	    text-align: center;
	    color: #000;
	    text-decoration: none;
	    font-size: 20px;
	    font-weight: bold;
	    line-height: 1.8;
	    padding: 20px;
	}

	.guideBnr {
	    /* max-width: 800px; */
	    margin: 0 auto 20px;
	    padding: 10px;
	    width: 50%;
	}

	.guideBnr a span {
	    background-color: #000;
	    padding: 3px 40px;
	    margin-top: 10px;
	    color: #FFFFFF;
	    font-size: 18px;
	    display: inline-block;
	}
}



@include media(sp){
body#top{
}
}


@media screen and (max-width: 980px) {

}

@import "_old-style-pc";