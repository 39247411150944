/*
file: _setting.scss
Description: 
Version: 2.2.0
Changelog: 【URL】
Author: NAME
License: 
*/


// ------------------------------------------------------------
// #命名規則
// BEMをベースに以下のルールで記載。
// block_element,_modifier
// ------------------------------------------------------------

// ------------------------------------------------------------
// #インデント
// インデントの種類はスペースを使用
// インデントのサイズはスペース2つ分
// ------------------------------------------------------------

// ------------------------------------------------------------
// #ブレークポイントの基本的な考え方
// スマートフォンファースト
// スマホ用CSSを先に記述し、メディアクエリ内でタブレットやPC用の記述をする
//
// ブレークポイント（$breakpoints）
// 基本的なブレークポイントとして、
// 768px（SPデザインとPCデザインの切り替え）を設定し、個別に
// ・769px以上960px未満
// ・960px以上
// を設定しています。
// ------------------------------------------------- メディアクエリ
// ブレークポイント一覧
//
//           769px     960px     1280px
//            ○          ○         ○
//      （sp） ｜ （tab）  ｜  (pc)  ｜
//   --------▶｜◀-------▶｜◀-------------
//            ｜         ｜
//            ｜（tab-sp）｜
//   -------------------▶｜
//            ｜
//            ｜（screen）
//            ｜◀------------------------


$breakpoints: (
  screen:"only screen and (min-width:769px)",
      pc:"only screen and (min-width:960px)",
     tab:"only screen and (min-width:769px) and (max-width:959px)",
  tab-sp:"only screen and (max-width:959px)",
      sp:"only screen and (max-width:768px)",
);
@mixin media($breakpoint){
  @media #{map-get($breakpoints,$breakpoint)}{
    @content;
  }
}

@include media(screen){
}

@include media(sp){
}



// ------------------------------------------------------------------------- 案件・個人の好みで変更可能

// ------------------------------------------------------------------------- SNS系
$wp:#21759b;//WordPress
$tw:#55acee;//twitter
$yt:#cd201f;//YouTube
$fb:#315096;//Facebook



