/*--------------------------------------------------------------
# Variable
--------------------------------------------------------------*/
body {
  min-width: 1200px; }
  body .p-layout__col--secondary .p-socialLinks .p-socialLinks__items {
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: start;
    justify-content: start;
    -webkit-align-content: space-between;
    align-content: space-between; }
    body .p-layout__col--secondary .p-socialLinks .p-socialLinks__items .p-socialLinks__item {
      height: auto;
      margin: 0;
      margin-left: 5px; }
      body .p-layout__col--secondary .p-socialLinks .p-socialLinks__items .p-socialLinks__item a {
        width: 45px;
        height: 45px;
        position: relative; }
        body .p-layout__col--secondary .p-socialLinks .p-socialLinks__items .p-socialLinks__item a .fa {
          position: absolute;
          top: 50%;
          left: 50%;
          -ms-transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          font-size: 20px;
          width: auto; }
        body .p-layout__col--secondary .p-socialLinks .p-socialLinks__items .p-socialLinks__item a span {
          display: none; }
    body .p-layout__col--secondary .p-socialLinks .p-socialLinks__items .p-socialLinks__item:first-child {
      margin-left: 0; }

body.home .information .c-card__title {
  position: relative; }

body.home .information .c-btn.c-btn--default {
  position: absolute;
  right: 10px;
  top: 10px;
  box-sizing: border-box;
  display: inline-block;
  padding: 4px 20px 6px 20px;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px;
  border: 2px solid transparent;
  border-color: currentColor; }

body.home .linkWrap.cmp3aBox {
  position: relative;
  padding: 30px 10px;
  background: transparent url(../img/member-bg.png) no-repeat scroll center top;
  background-size: cover;
  height: auto; }
  body.home .linkWrap.cmp3aBox .cmp3aList {
    padding: 20px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 20px;
    color: #000;
    position: relative;
    z-index: 100; }
    body.home .linkWrap.cmp3aBox .cmp3aList li {
      font-weight: bold !important; }
    body.home .linkWrap.cmp3aBox .cmp3aList .cmpClosed {
      border: 1px solid #000; }
  body.home .linkWrap.cmp3aBox .cmp3aList:before {
    content: "";
    display: block;
    width: 260px;
    height: 80px;
    background: transparent url(../img/member-ttl02_2.png) no-repeat scroll center top;
    background-size: contain;
    margin: 0 auto 10px auto; }

body.home .member-ticket {
  width: 350px;
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0); }

body.home .member-ttl01 {
  text-align: center;
  display: block;
  margin-bottom: 25px; }
  body.home .member-ttl01 img {
    width: 350px;
    margin: 0 auto; }

@media screen and (min-width: 980px) {
  .pc {
    display: block; }
  .sp {
    display: none; } }

@media screen and (max-width: 980px) {
  .pc {
    display: none; }
  .sp {
    display: block; } }

.ininbound-block{
    border: 1px solid #333;
    padding: 20px;
    margin: 10px 0 20px 0;
}
.ininbound-block h4{
    font-family: "TeXGyreAdventor", "futura-pt", "futura", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
    font-size: 20px;
    margin: 0 0 20px 0;
}
.ininbound-block p{
    margin: 0;
    font-size: 14px;
    font-weight: normal;
}
.ininbound-block a{
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 0;
    word-break: break-all;
}